import PropTypes from "prop-types";
import React from "react";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import AppStore from "../../store/AppStore";

const DropdownSingle = ({
  id,
  name,
  controlValuesList,
  handleChange,
  handleInteraction,
  currentValue,
  defaultValue,
  validation,
  parentName,
}) => {
  const formGroupClass =
    isNullOrUndefined(parentName) || parentName === "" ? "dropdown-single" : "";

  if (currentValue.length === 0 && controlValuesList.length > 0) {
    if (defaultValue) {
      let index = controlValuesList.findIndex((el) => el.Key === defaultValue.toString());
      AppStore.setFormData({ [name]: controlValuesList[index].Key });
    } else AppStore.setFormData({ [name]: controlValuesList[0].Key });
  }
  const errorClass = validation && validation.isValid === false ? "error" : "";
  const versionType =
    !isNullOrUndefined(AppStore.getUserJourneySettings().customControls) &&
    !isNullOrUndefined(AppStore.getUserJourneySettings().customControls.customRadio)
      ? AppStore.getUserJourneySettings().customControls.customRadio
      : "";

  return (
    <div
      className={`form-group form-group-${name} ${formGroupClass} d-inline-block ${
        versionType === "button"
          ? "col-lg-8 col-md-8 col-sm-12 col-12"
          : `col-lg-4 col-md-5 col-sm-6 col-12`
      }`}>
      <div className={`dropdown-single row${errorClass ? ` row-${errorClass}` : ""}`}>
        <select
          aria-label={name.replace(/-/g, " ")}
          className="form-control"
          id={id}
          name={name}
          onChange={(event) => {
            handleChange(event);
            handleInteraction(event);
          }}
          value={currentValue || defaultValue}>
          {controlValuesList.map((item) => {
            return (
              <option value={item.Key} key={name + "_" + item.Key}>
                {item.Value}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

DropdownSingle.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DropdownSingle;
